@import "../../../styles/variables-colors";

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  gap: 15px;
  width: 72px;
  height: 100%;
  padding: 0 8px 24px;
}

.fullWidthContainer {
  width: 280px;
  padding: 0 12px 24px;
  transition: width 0.2s ease-in 0.1s;
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
}

.fullWidthLogo {
  padding-left: 18px;
  justify-content: unset;
}

.avatarContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  padding: 4px 0;

  &.fullWidthAvatar {
    justify-content: space-between;
    padding: 4px 18px 4px 24px;
    transition: padding 0.5s ease;
  }
}

.avatarContainer:hover {
  background-color: $selected;
}

.avatar {
  display: flex;
  flex: 0 0 32px;
  background-color: #BDBDD3;
}

.avatarName {
  margin: 0 12px;
  flex: 1 0;
  white-space: nowrap;
}

.actions {
  font-size: 20px;
  color: #8784AD;
}

.actions:hover {
  cursor: pointer;
}

.logOutActionWrapper {
  gap: 8px;
  width: 256px;
  background-color: white;
  height: 40px;
  border-radius: 8px;
}

.logOutAction {
  display: flex;
  gap: 8px;
  align-items: center;
  vertical-align: center;
  padding: 9px 10px;
  cursor: pointer;
  border-radius: 8px;
}

.logOutAction:hover {
  background-color: $selected;
}

.menuContainer {
  height: 100%;
  overflow: auto;
}

.logo svg {
  width: 24px;
  height: 24px;
}
