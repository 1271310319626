@import "variables-colors";

/* Here we customized border and background colors because in ANTD
 those colors are equal to colorPrimary, but we want to have them different. */
.ant-radio-button-wrapper-checked {
  border-color: $colorPrimaryBorderHover !important;
}

.ant-radio-button-wrapper-checked:before {
  background-color: $colorPrimaryBorderHover !important;;
}

// TODO: Move from global styles to fix font-size on the Pending Investments page.
.ant-dropdown-menu-submenu-title, .ant-dropdown-menu-item {
  font-size: 12px !important;
}

.ant-table-filter-dropdown-btns .ant-btn {
  font-size: 12px !important;
}
