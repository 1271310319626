@import "variables-colors";

html,
body {
  padding: 0;
  margin: 0;
  font-family: Archivo, "Source Sans Pro", sans-serif;
  color: $ink-color;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

input[type="checkbox"] {
  width: 16px;
  height: 16px;
  margin: 0;
  opacity: 0;
  position: absolute;
  inset: 0;
  z-index: 1;
  cursor: pointer;
}

.highlighted-light-green-grid-row {
  background-color: rgb(211, 231, 197) !important;
}

.highlighted-light-yellow-grid-row {
  background-color: rgb(255, 255, 204) !important;
}

.highlighted-dark-green-grid-row {
  background-color: rgb(88, 191, 111) !important;
}

#__next {
  height: 100%;
}

//REMOVE ???
.ant-menu-overflow-item {
  display: flex !important;
  align-items: center !important;
}

.ant-divider-horizontal.ant-divider-with-text {
  margin: 20px 0 !important;
}

.ant-divider-horizontal.ant-divider-with-text {
  margin: 20px 0 10px !important;
}


